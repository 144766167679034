import React, { useState, useContext } from "react";
import styled from "@emotion/styled";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Grid, Row, Col } from "react-flexbox-grid";
import { getAffiliateLinkWithMetadata, rankProviders } from "../../utils/index";
import cloudinary from "../../utils/cloudinary";
import GeoContext from "../context/geoContext";
import { CtaButton } from "./styledComponents";
import FlagCheckmark from "./flagCheckmark.js";

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const ProviderContainer = styled(Row)`
  position: relative;
  background: #fff;
  margin-bottom: 32px;
  overflow: visible;
  box-shadow: rgba(72, 72, 72, 0.1) 0px 0px 5px 3px;
  color: ${props => props.placeholder ? "#fff" : "#333"};
  border: ${props => props.first ? `1px solid ${props.theme.highlightColor || props.theme.accentColor}` : "none"};
  border-radius: 6px;

  h3 {
    font-weight: 700;
    font-size: 25px;

    @media only screen and (min-width: 1200px) {
      font-size: 25px;
    }
  }

  .placeholderHidden {
    opacity: ${props => props.placeholder ? "0" : "1"};
    pointer-events: ${props => props.placeholder ? "none" : "auto"};
  }

  .content {
    font-size: 17px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: ${props => props.rtl ? "right" : "left"};
    justify-content: center;
    padding-right: 10px;
  }

  .providerLink {
    color: #000;
    font-size: 14px;
    display: block;
    text-decoration: underline;
    text-align: center;

    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  .rank {
    font-size: 24px;
    font-weight: 500;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .bonus-heading {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 8px;
  }

  .bonus {
    display: block;
    font-size: ${(props) => props.longbonus ? "20px" : "20px"};
    line-height: ${(props) => props.longbonus ? "22px" : "22px"};
    color: ${props => props.theme.accentColor};
    font-weight: bold;

    @media only screen and (min-width: 768px) {
      font-size: 22px;
      line-height: 23px;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 24px;
      line-height: 25px;
    }
  }

  .logoContainer {
    background: ${(props) => props.backgroundcolor ? props.backgroundcolor : "#fff"};
    display: flex;
    align-items: center;
    align-self: auto;
    position: relative;
    top: -10px;
    border-radius: 5px;
    padding: 0px;
    height: 95px !important;
    width: 95px;
    height: auto;

    @media only screen and (min-width: 768px) {
      padding: 0px;
      height: 125px !important;
      width: 125px;
      height: auto;
    }
  }

  .ranking {
    position: absolute;
    top: 20px;
    left: ${props => props.rtl ? "unset":"0px"};
    right: ${props => props.rtl ? "0px":"unset"};
    transform: ${props => props.rtl ? "translateX(calc(100% + 1px))" : "translateX(calc(-100% + 1px))"};
    background: ${props => props.top3 ? props.theme.secondaryColor : "#000"};
    color: #fff;
    padding: 3px 4px;
    font-size: 12px;
    border-top-left-radius: ${props => props.rtl ? "0":"5px"};
    border-bottom-left-radius: ${props => props.rtl ? "0":"5px"};
    border-top-right-radius: ${props => props.rtl ? "5px":"0"};
    border-bottom-right-radius: ${props => props.rtl ? "5px":"0"};

    @media only screen and (min-width: 768px) {
      padding: 3px 7px;
    }
  }

  .usp {
    display: block;
    font-size: 16px;
  }

  .title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    background: #000;
    padding: 3px;
    text-transform: uppercase;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .score {
    display: inline-block;
    font-size: 40px;
    font-weight: 900;
    line-height: 20px;
    color: #fff;
    padding: 10px;
  }

  .terms {
    margin-top: 5px;
    p, a {
      color: #333;
      text-align: left;
    }
  }

  .banner {
    background: ${props => props.theme.accentColor};
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    width: max-content;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
  }

  .flagContainer {
    font-size: 12px;
  }

  .showMoreContent {
    img {
      display: none;
    }
  }
`;

const Logo = styled.a`
  width: calc(100% - 10px);
  max-width: 200px;
  height: 80px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    max-width: none;
    height: 100px;
  }
`;

const Usps = styled.div`
  font-size: 11px;

  ul {
    padding-inline-start: 15px;
  }

  li::marker {
    color: #81858b;
  }
`;

const ShowMore = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  background: #fff;
  margin: 0 auto;
  color: ${(props) => props.theme.secondaryColor};
  padding: 20px 30px;
  border-radius: 50px;
  border: 20px solid ${(props) => props.theme.tertiaryColor};
  text-transform: uppercase;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  white-space: nowrap;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${(props) => props.theme.secondaryColor};
    border-radius: 50px;
  }
`;

const ProviderAboutContainer = styled.div`
  background: #fff;
  font-size: 12px;
  display: ${props => props.visible ? "block":"none"};
  border-bottom-left-radius: ${props => props.theme.borderRadius};
  border-bottom-right-radius: ${props => props.theme.borderRadius};

  & p, span, h2, h3, h4 {
    font-size: 12px;
    margin: 0;
  }

  a {
    text-decoration: underline;
    color: ${props => props.theme.secondaryColor}
  }
`;

const placeholder = {
  "placeholder": true,
  "name": "-",
  "bonus": "-",
  "bonus-text": "-",
  "pros": "-",
  "about-provider": "&nbsp;",
  "tc-text": "-",
  "properties": {
      "logotype": {
          "resolved": [
              {
                  "url": "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
              }
          ]
      },
      "affiliate-link": {
          "data": {
              "value": "#"
          }
      },
  },
  "path": "#",
}

export default (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    providers: _providers,
    count,
    scrollPosition,
    pageContext,
    dynamic,
    providerCountCallback,
    maxItemsToShow = 10,
    outboundClickPrefix,
    resourceId
  } = props;

  const { site, themeSettings } = pageContext;
  const { disableImgRewrites = false, disableListings = false } = themeSettings;
  const geoContext = useContext(GeoContext);
  const showMore = site["read-more"] || "Show more";
  const showLess = site["read-less"] || "Show less";
  const providers = dynamic ? geoContext?.providers : _providers;

  if (disableListings) return null

  function sliceProviders({ providers, count }) {
    const output = providers?.slice(0, count ? count : providers.length) || [];
    if (providerCountCallback) {
      providerCountCallback(output?.length)
    }
    return output
  }

  let outputProviders = sliceProviders({
    count: count,
    providers: rankProviders({
      providers: providers,
      geoContext,
    }),
  });

  if (!outputProviders?.length) {
    outputProviders = []
    for (var i=0;i<maxItemsToShow;i++) {
      outputProviders.push(placeholder)
    }
  }

  outputProviders = outputProviders.filter(p => {
    // Filter out providers missing affiliate link
    if (!p?.properties?.["affiliate-link"]?.data?.value) {
      return false
    } 
    return true
  })

  

  return (
    <GeoContext.Consumer>
      {(geoContext) => (
        <Container className={"pb-2 " + (props.className ? props.className : "")}>
          {outputProviders?.map((provider, i) => {
            if (!expanded && i > maxItemsToShow - 1) return null;
            return (
              <Provider
                key={i}
                i={i}
                geoContext={geoContext}
                scrollPosition={scrollPosition}
                pageContext={pageContext}
                provider={provider}
                outboundClickPrefix={outboundClickPrefix}
                disableImgRewrites={disableImgRewrites}
                resourceId={resourceId}
              />
            );
          })}
          {outputProviders.length > maxItemsToShow ? (
            <ShowMore onClick={() => setExpanded(!expanded)}>
              {expanded ? showLess : showMore}
            </ShowMore>
          ) : null}
        </Container>
      )
      }
    </GeoContext.Consumer >
  );
};

const ShowMoreLink = styled.a`
  padding: 4px;
  padding-left: 0px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  margin-top: 0;
  color: #333;
`;

const Provider = (props) => {
  const { i, pageContext, scrollPosition, geoContext, outboundClickPrefix = "list_", disableImgRewrites = false, resourceId } = props;
  let { provider } = props
  const { site, themeSettings = {} } = pageContext;
  const { countryCode } = geoContext
  const { reviewLinks, disableBonuses = false } = themeSettings;
  const [loaded, setLoaded] = useState(false);
  const [showingAbout, setShowingAbout] = useState(false);
  const { properties } = provider;
  const backgroundColor = properties["background-color"]?.data?.value;
  const rtl = site?.properties?.rtl?.data?.value

  const hasAboutText = provider["about-provider"] ? true : false;
  const showMore = site["read-more"] || "Show more";
  const showLess = site["read-less"] || "Show less";

  // Link T&C string template if tc-url is set
  const tcUrl = provider?.properties?.["tc-url"]?.data?.value
  if (tcUrl && provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a") === -1) {
    provider["tc-text"] = `<a href=${tcUrl} rel="noindex nofollow noopener noreferrer">${provider?.["tc-text"]}</a>`
  } else if (provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a href") > -1) {
    provider["tc-text"] = provider["tc-text"]?.replace(/<a href/g, "<a rel='noindex nofollow noopener noreferrer' href")
  }

  function getUsps({ provider, geoContext }) {
    const { acceptedPlayers } = geoContext;

    let usps = acceptedPlayers ? [acceptedPlayers] : [];
    if (provider["pros"]) {
      if (Array.isArray(provider["pros"])) {
        return [...usps, ...provider.pros];
      } else {
        const pros =
          provider?.pros.indexOf(",") > -1
            ? provider?.["pros"]?.split(",")
            : [provider?.["pros"]];
        return [...usps, ...pros];
      }
    }

    if (provider["main-usp"]) {
      let main = provider["main-usp"].split("\n");
      return [...usps, ...main];
    }
    return
  }

  const nakedAffiliateLink = provider?.properties?.["affiliate-link"]?.data?.value?.slug ? "https://" + site.url + "/goto/" + provider?.properties?.["affiliate-link"]?.data?.value?.slug : provider?.properties?.["affiliate-link"]?.data?.value

  return (
    <ProviderContainer
      key={"p" + i}
      backgroundcolor={backgroundColor}
      longbonus={provider?.["bonus"]?.length > 10 ? 1 : undefined}
      rtl={rtl}
      first={i === 0 ? 1 : undefined}
      top3={i <= 2 ? 1 : undefined}
      className="mx-1"
      placeholder={provider?.placeholder ? 1:undefined}
    >
      <Col xs={12} md={4} className="d-flex flex-row justify-content-start align-items-center">
      <Col className="pl-2 pr-3 pr-md-3">
        <div  className="logoContainer">
          <div className="ranking">
            #{i+1}
          </div>
          <LazyLoadComponent
            afterLoad={() => setLoaded(true)}
            scrollPosition={scrollPosition}
          >
            <Logo
              href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, clickType: "logo", resourceId})}
              className="outlink placeholderHidden"
              target="_blank"
              rel="noindex nofollow noopener noreferrer"
              src={
                (provider?.placeholder ? provider?.properties?.logotype?.resolved?.[0].url : (loaded &&
                  cloudinary.url(
                    provider?.properties?.logotype?.resolved?.[0].url,
                    { height: 200 },
                    disableImgRewrites
                  )) ||
                null)
              }
              onClick={() => {
                window._paq.push(['trackEvent', `${outboundClickPrefix}${i + 1}`, provider?.name, 'Outclick: Logo'])
              }}
              role="img"
              aria-label={provider?.name}
            />
          </LazyLoadComponent>
        </div>
      </Col>

        <div className="mt-3 mt-md-0 align-self-start align-self-md-center">
          <div className="d-flex flex-row align-items-center placeholderHidden">
            {i < 2 ? <span className="banner mb-0 mb-md-2 mr-2">Hot</span> : null}
            {countryCode ? (
              <div className="d-md-none">
                <FlagCheckmark countryCode={countryCode} />
              </div>
            ) : null}
          </div>

          <h3 className="mt-0 mb-2">{provider.name}</h3>
          <div style={{ position: "relative" }}>
            {countryCode ? <div className="flagContainer placeholderHidden d-none d-md-flex align-items-center"><FlagCheckmark countryCode={countryCode} /> {/* <Flag src={`/flags/${countryCode}.svg`} alt={countryCode + " Country Flag"} fit="cover" className="mr-2" />Players from Sweden accepted */}</div> : null}
          </div>
        </div>
      </Col>

      <Col xs={12} md={8} className="d-flex flex-column flex-md-row pl-4 pr-1 px-md-2">
        <Row style={{ width: "100%" }} className="d-block d-md-flex">
          <Col
            xs={12}
            mdOffset={0}
            md={6}
            className="pt-1 pb-0 py-md-2 pl-md-1 d-flex flex-direction-row align-items-center text-center px-0"
          >
            <div className="d-flex flex-direction-row">
              <div className="content text-md-left placeholderHidden">
                {disableBonuses ? null:(
                  <>
                    {(provider?.["bonus"] || provider?.["bonus-text"]) ? <span className="bonus-heading d-block mb-2">{site?.["bonus-heading"] || "Bonus"}</span> : null}
                    <span className="bonus mb-0">{provider?.["bonus"] || provider?.["bonus-text"]}</span>
                  </>
                )}
                {provider["tc-text"] && provider["tc-text"] !== "undefined" ? <div className="terms" dangerouslySetInnerHTML={{
                  __html: provider?.["tc-text"],
                }} /> :
                  null}
              </div>
            </div>
          </Col>

          <Row className="py-3 d-md-none placeholderHidden">
            <Col xs={6} className="d-block d-md-none">
              <CtaButton
                className="outlink d-block glow mb-2"
                href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, resourceId})}
                onClick={() => {
                  window._paq.push(['trackEvent', `${outboundClickPrefix}${i + 1}`, provider?.name, 'Outclick: Button'])
                }}
                target="_blank"
                rel="noindex nofollow noopener noreferrer"
              >
                {site["cta-alternative"] || "Get your bonus"}
              </CtaButton>
            </Col>
            <Col xs={6} className="d-block d-md-none">
              <div className="d-md-none mb-2">
                {reviewLinks && provider?.path ?
                  <a href={provider.path} className="providerLink"><div>{provider.name} {site?.["review-label"]}</div></a> : null
                }
              </div>

              {hasAboutText ? (
                <ShowMoreLink
                  onClick={() => {
                    setShowingAbout(!showingAbout)
                    window._paq.push(['trackEvent', `${outboundClickPrefix}${i + 1}`, provider?.name, 'Show More'])
                  }}
                  placeholder={provider?.placeholder ? 1:undefined}
                  className="d-block d-md-none text-center">

                  {showingAbout ? (
                    <svg width="8px" height="5px" viewBox="0 0 8 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="mr-2">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-4.000000, -6.000000)" fill="#81858B">
                                <path d="M4.69647719,8.8825 L7.28647719,6.2925 C7.67647719,5.9025 8.30647719,5.9025 8.69647719,6.2925 L11.2864772,8.8825 C11.9164772,9.5125 11.4664772,10.5925 10.5764772,10.5925 L5.39647719,10.5925 C4.50647719,10.5925 4.06647719,9.5125 4.69647719,8.8825 Z"></path>
                            </g>
                        </g>
                    </svg>
                  ):(
                    <svg width="8px" height="5px" viewBox="0 0 8 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="mr-2">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-4.000000, -6.000000)" fill="#81858B">
                                <path d="M4.69647719,7.71 L7.28647719,10.3 C7.67647719,10.69 8.30647719,10.69 8.69647719,10.3 L11.2864772,7.71 C11.9164772,7.08 11.4664772,6 10.5764772,6 L5.39647719,6 C4.50647719,6 4.06647719,7.08 4.69647719,7.71 Z"></path>
                            </g>
                        </g>
                    </svg>
                  )}

                  {showingAbout ? showLess : showMore}
                </ShowMoreLink>
              ) : null}
            </Col>
          </Row>

          <Col
            xs={12}
            md={3}
            className="d-none d-md-flex flex-column align-items-left justify-content-center mt-md-3 mb-md-2 placeholderHidden pl-md-0"
          >
            {getUsps({ provider, geoContext })?.length ? (
              <Usps>
                {site?.features?.toUpperCase() || "FEATURES"}
                <ul className="my-1">
                {getUsps({ provider, geoContext })?.slice(0,3)?.map(usp => {
                  return usp ? <li>{usp}</li>:null
                })}
                </ul>
              </Usps>
            ):null}

            {hasAboutText ? (
              <ShowMoreLink
                onClick={() => {
                  setShowingAbout(!showingAbout)
                  window._paq.push(['trackEvent', `${outboundClickPrefix}${i + 1}`, provider?.name, 'Show More'])
                }}
                className="d-none d-md-block">
                {showingAbout ? showLess : showMore}
              </ShowMoreLink>
            ) : null}
          </Col>

          <Col xs={12} md={3} className="content d-none d-md-flex py-3 px-0 placeholderHidden">
            <CtaButton
              className="outlink d-block glow mb-1"
              href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, resourceId})}
              onClick={() => {
                window._paq.push(['trackEvent', `${outboundClickPrefix}${i + 1}`, provider?.name, 'Outclick: Button'])
              }}
              target="_blank"
              rel="noindex nofollow noopener noreferrer"
            >
              {site["cta-alternative"] || "Get your bonus"}
            </CtaButton>
            {reviewLinks && provider?.path ?
              <a
                href={provider.path}
                onClick={() => {
                  window._paq.push(['trackEvent', `${outboundClickPrefix}${i + 1}`, provider?.name, 'Review'])
                }}
                className="providerLink">
                <div>{provider.name} {site?.["review-label"]}</div>
              </a> : null
            }
          </Col>
        </Row>
      </Col>

      <LazyLoadComponent
        scrollPosition={scrollPosition}
      >
        <ProviderAboutContainer className="px-3 px-md-2 pt-0 pb-3 pb-md-3 pt-md-2 placeholderHidden" visible={showingAbout && hasAboutText}>
          <Usps className="d-block d-md-none mb-3">
          {site?.features?.toUpperCase() || "FEATURES"}
          <Row className="mt-2">
          {getUsps({ provider, geoContext })?.map(usp => {
            return usp ? <li className="col-xs-6 col-6">{usp}</li>:null
          })}
          </Row>
          </Usps>
            <div dangerouslySetInnerHTML={{
              __html: provider["about-provider"],
            }}
              className="showMoreContent"
             />
        </ProviderAboutContainer>
      </LazyLoadComponent>

    </ProviderContainer >
  );
};
