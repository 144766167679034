import React from "react"
import styled from "@emotion/styled"
import Helmet from "react-helmet"

const QuestionContainer = styled.div`
  position: relative;
  z-index: 1;
`

const Question = styled.div`
  text-align: ${props => (props.rtl ? "right" : "left")};
  display: inline-block;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: ${props => props.theme.borderRadius};
  cursor: pointer;
  display: block;
  margin-bottom: ${props => props.expanded ? "15px" : "6px"};
  box-shadow: ${props => props.expanded ? props.theme.tintedShadow : "none"};

  h3 {
    font-weight: 600 !important;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    margin: 0;
    color: ${props => props.expanded ? props.theme.secondaryColor : "#000"};
  }

  .expandIcon {
    width: 30px;
    height: 30px;
  }
`

const Answer = styled.div`
  text-align: ${props => (props.rtl ? "right" : "left")};
  text-transform: none;
  list-style: none;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0, 1, 0, 1);

  &.expanded {
    max-height: 1000px;
  }

  & > p {
    font-size: 18px;
  }

  & > p:first-of-type /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
    margin-top: 0;
  }
`

class Faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: [] }
  }

  renderSchemaTags(questions) {
    let formattedQuestions = []

    questions.forEach(q => {
      if (q.question && q.answerText) {
        formattedQuestions.push({
          "@type": "Question",
          name: q.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: q.answerText,
          },
        })
      }
    })

    const schemaTag = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: formattedQuestions,
    }

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaTag)}</script>
      </Helmet>
    )
  }
  renderFaq(content) {
    const { twoColumns } = this.props
    const questions = content
    let output = []
    questions.forEach((q, i) => {
      let _question = []
      _question.push(
        <Question
          key={i}
          onClick={() => {
            let { expanded = [] } = this.state
            if (expanded.indexOf(i) > -1) {
              expanded.splice(expanded.indexOf(i), 1)
              this.setState({ expanded: expanded })
            } else {
              expanded.push(i)
              this.setState({ expanded: expanded })
            }
          }}
          expanded={this?.state?.expanded?.indexOf(i) > -1}
          className="p-4"
        >
          <div className="d-flex" style={{justifyContent:"space-between", alignItems:"center"}}>
            <h3>{q.question}</h3>
            {this?.state?.expanded?.indexOf(i) > -1 ? <img src="/img/collapseLarge.svg" alt="Expand" className="expandIcon" /> : <img src="/img/expandLarge.svg" alt="Expand" className="expandIcon" />}
          </div>
          {q.answerHtml ? (
              <Answer
                key={"answer-" + i}
                className={this.state.expanded.indexOf(i) > -1 ? "expanded pt-4" : ""}
                id={"faq" + i}
                dangerouslySetInnerHTML={{
                  __html: q.answerHtml,
                }}
              />
          ):null}
        </Question>
      )


      output.push(
        <QuestionContainer
          key={"qc-" + i}
          className={twoColumns ? "col-xs-12 col-md-6" : "col-xs-12 px-0"}
          twoColumns={twoColumns ? twoColumns : undefined}
        >
          {_question}
        </QuestionContainer>
      )
    })
    return (
      <div className="row mx-0">
        {this.renderSchemaTags(questions)}
        {output}
      </div>
    )
  }
  render() {
    const { content } = this.props
    return this.renderFaq(content)
  }
}

export default Faq
